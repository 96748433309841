@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Seurat';
  src: url('./assets/fonts/FOT-Seurat\ Pro\ EB.otf')
}

@font-face {
  font-family: 'RodinRose';
  src: url('./assets/fonts/FOT-RodinRose\ Pro\ B.otf')
}

@font-face {
  font-family: 'RodinRoseEB';
  src: url('./assets/fonts/FOT-RodinRose\ Pro\ EB.otf')
}

@layer base {
  * {
    @apply font-seurat box-border m-0 p-0 transition-all
  }

  *:not(path) {
    @apply text-brown
  }

  html {
    @apply bg-nookLeaf bg-lightBlue bg-blend-soft-light
  }

  h1 {
    @apply text-2xl
  }

  h3 {
    @apply text-lg
  }

  a {
    @apply text-lg
  }

  p {
    @apply text-base
  }

  button {
    @apply bg-yellow px-6 py-2 rounded-3xl hover:bg-darkYellow
  }

  li {
    @apply border-b-[1px] py-2
  }

  input {
    @apply outline-none
  }

  input[type="checkbox"] {
    @apply w-5 h-5 appearance-none rounded-full border-2 border-brown/10 checked:border-brown hover:cursor-pointer
  }


  .navItem-hover {
    @apply w-full h-4 bg-darkYellow mt-[-0.75rem] absolute rounded-xl hidden group-hover:block z-[-1]
  }

  .divs {
    @apply bg-teal/50 rounded-full p-1 outline outline-none
  }

  .selected {
    @apply bg-nookLeaf bg-teal outline-darkTeal
  }
}